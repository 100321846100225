// @flow

import { useRef } from "react";
import styled, { css } from "styled-components";

import { getImage } from "@nested/utils";
import { media } from "@nested/brand";
import { Button, ButtonLink } from "@nested/component-library";

import {
  smallParagraph,
  mediumParagraph,
} from "../../components/Typography/Paragraphs";
import { Carousel } from "../../components/Carousel/Carousel";
import { LeftArrow, RightArrow, Trustpilot } from "../../icons";

const background = css`
  background-color: ${({ theme }) => theme.palette.sand40};
  overflow: hidden;
  text-align: center;
`;

const Header = styled.div`
  ${background}
  align-items: ${({ leftAlignHeaderMobile }) =>
    leftAlignHeaderMobile ? "flex-start" : "center"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px 0 20px;
  ${media.tablet`
    align-items: center;
  `}
  ${media.desktop`
    align-items: flex-end;
    flex-direction: row;
    padding-top: 60px;
  `}
`;

const Footer = styled.div`
  ${background}
  padding: 0 20px 40px 20px;

  ${media.desktop`
    padding-bottom: 60px;
  `}
`;

const FiveStars = styled.img`
  height: 24px;
`;

const HeaderText = styled.p`
  ${mediumParagraph}
  margin-bottom: 0;
  margin-top: 20px;

  ${media.desktop`
    margin: 0 20px;
  `}
`;

const SliderWrapper = styled.div`
  ${background}

  position: relative;

  ${media.desktop`
    padding: 30px 0;
  `}
`;

const FadeWrapper = styled.div`
  display: none;

  ${media.maxDesktop`
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
  `}
`;

// This uses non-theme colours. Get over it, it looks shit with theme colours.
const LeftFade = styled.div`
  background-image: linear-gradient(270deg, rgba(250, 247, 244, 0), #faf7f4);
  height: 100%;
  position: absolute;
  width: 40px;
  z-index: 1;
`;

// This uses non-theme colours. Get over it, it looks shit with theme colours.
const RightFade = styled.div`
  background-image: linear-gradient(90deg, rgba(250, 247, 244, 0), #faf7f4);
  height: 100%;
  position: absolute;
  right: 0;
  width: 40px;
  z-index: 1;
`;

const ReviewWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 5px 0 ${({ theme }) => theme.palette.sand100};
  margin: 30px 20px;
  padding: 30px 20px;
  text-align: left;

  p {
    margin: 0;
  }
`;

const ReviewFooter = styled.div`
  ${smallParagraph}
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Customer = styled.p`
  ${smallParagraph}

  && {
    margin-right: 10px;
    margin-top: 20px;
  }
`;

const ReviewStars = styled.img`
  margin-top: 20px;
`;

const arrowStyle = css`
  width: 24px;
  display: block;
  ${media.tablet`
    width: 38px;
 `}
`;

const allReviews = css`
  margin: 0px 10px;
  ${media.tablet`
    margin: 0px 20px;
  `};
`;

const trustpilotLogo = css`
  width: 120px;
  height: 30px;
  margin-top: 10px;
`;

const Review = ({ customer, review }) => (
  <ReviewWrapper>
    <p css={smallParagraph}>“{review}”</p>
    <ReviewFooter>
      <Customer>
        <strong>{customer}</strong>
      </Customer>
      <ReviewStars
        alt="Five stars"
        src={getImage("icons/Trustpilot_stars_only.svg")}
      />
    </ReviewFooter>
  </ReviewWrapper>
);

const sliderSettings = {
  arrows: false,
  centerMode: true,
  centerPadding: "8%",
  swipe: false,
  touchMode: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1179,
      settings: {
        centerPadding: "0",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 859,
      settings: {
        centerPadding: "17%",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 479,
      settings: {
        centerPadding: "0",
        slidesToShow: 1,
      },
    },
  ],
};

type PrismicReview = {
  customer: string,
  review: string,
};

type Props = {
  repeat: Array<PrismicReview>,
  leftAlignHeaderMobile?: boolean,
};

export const TrustpilotCarousel = ({
  repeat = [],
  leftAlignHeaderMobile,
}: Props) => {
  const slider = useRef();
  return (
    <>
      <Header leftAlignHeaderMobile={leftAlignHeaderMobile}>
        <FiveStars
          alt="Five stars"
          src={getImage("icons/Trustpilot_stars_only.svg")}
        />
        <HeaderText>
          Rated <strong>excellent</strong> by sellers on
        </HeaderText>
        <a
          href="https://www.trustpilot.com/review/www.nested.com"
          target="_blank"
          css={css`
            display: inline-flex;
          `}
        >
          <Trustpilot css={trustpilotLogo} />
        </a>
      </Header>

      <SliderWrapper>
        <FadeWrapper>
          <LeftFade />
          <RightFade />
        </FadeWrapper>
        <Carousel settings={sliderSettings} sliderRef={slider}>
          {repeat.map((review, index) => (
            <Review {...review} key={`review_${index}`} />
          ))}
        </Carousel>
      </SliderWrapper>

      <Footer>
        <div>
          <Button
            type="white"
            noText
            onClick={() => {
              if (!slider.current) return;
              slider.current.slickPrev();
            }}
          >
            <LeftArrow css={arrowStyle} />
          </Button>
          <ButtonLink
            css={allReviews}
            to="https://www.trustpilot.com/review/www.nested.com"
            target="_blank"
          >
            See all reviews
          </ButtonLink>
          <Button
            type="white"
            noText
            onClick={() => {
              if (!slider.current) return;
              slider.current.slickNext();
            }}
          >
            <RightArrow css={arrowStyle} />
          </Button>
        </div>
      </Footer>
    </>
  );
};
